import { FC, useRef, useState } from 'react'
import styled from 'styled-components'
import copyImage from '../assets/images/copy.svg'
import success from '../assets/images/success.svg'

const StyledField = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: var(--color-input);
  border-radius: var(--border-radius);
  padding: 10px;
  outline: none;
  border: none;
  min-width: fit-content;
  min-height: 40px;
`

interface FieldProps {
  image?: string
  text?: string | number
  copy?: boolean
  currency?: string
}

const Field: FC<FieldProps> = ({ image, text, copy, currency }) => {
  const [copied, setCopied] = useState(false)
  const textRef = useRef<HTMLSpanElement | null>(null)

  const handleCopy = () => {
    if (textRef.current && textRef.current.innerText) {
      // Извлекаем только числовое значение, включая десятичные дроби
      const numericValue =
        textRef.current.innerText.match(/\d+(\.\d+)?/)?.[0] || ''

      navigator.clipboard
        .writeText(numericValue)
        .then(() => {
          setCopied(true)
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err)
        })
    }
  }

  return (
    <StyledField
      style={{
        justifyContent: copy ? 'space-between' : '',
      }}
    >
      {image && (
        <img src={image} alt={text?.toString()} height={20} width={'auto'} />
      )}
      {text !== null || text !== undefined ? (
        <span ref={textRef}>{currency ? text + currency : text}</span>
      ) : null}
      {copy && !copied && (
        <img
          src={copyImage}
          alt='copy'
          onClick={handleCopy}
          width={19}
          height={19}
        />
      )}
      {copied && <img src={success} alt='success' width={19} height={19} />}
    </StyledField>
  )
}

export default Field
