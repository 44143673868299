import QRCode from 'qrcode.react'
import { FC } from 'react'
import styled from 'styled-components'
import alfa from '../assets/images/alfa.png'
import chc from '../assets/images/chc.png'
import rfs from '../assets/images/raiffeisen.png'
import sber from '../assets/images/sber.png'
import shield from '../assets/images/shield.svg'
import tin from '../assets/images/tinkoff.png'
import visaMir from '../assets/images/visamir.png'
import vovan from '../assets/images/vovan.png'
import Button from '../ui/Button'
import Field from '../ui/Field'

const Container = styled.div`
  width: auto;
  min-width: 320px;
  max-height: auto;
  background-color: var(--color-gold-light);
  margin: 0 auto;
  padding: 16px;
  border-radius: var(--border-radius);
`

const QRCodeDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const SmallFieldGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 7px;
`

const Labeled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`

const Timer = styled.p`
  font-size: 22px;
  color: var(--color-red);
  font-weight: 500;
  margin-left: auto;
`

const P = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 7px;
`

interface ActiveTransactionProps {
  merchantName?: string
  currency?: string
  ticketId?: string
  paymentMethod?: string
  amount?: number
  accountNumber?: string
  accountType?: string
  requesiteType?: string
  timeLeft?: string
  qrCodeData?: string
  isRedirectUrlExist?: boolean
  holderName?: string
  onCancel?: () => void
  onConfirm?: () => void
}

const ActiveTransaction: FC<ActiveTransactionProps> = (props) => {
  const {
    merchantName,
    currency,
    ticketId,
    paymentMethod,
    amount,
    accountNumber,
    accountType,
    requesiteType,
    timeLeft,
    onCancel,
    onConfirm,
    qrCodeData,
    isRedirectUrlExist,
    holderName
  } = props

  // SBER
  // YOOMONEY
  // TINKOFF
  // RAIFFEISEN
  // MC/VISA UAH
  // PRIVAT24 P2P UAH
  // ALFABANK

  const images = ['SBER', 'TINKOFF', 'RAIFFEISEN', 'MC/VISA UAH', 'ALFABANK']

  const renderImage = (paymentMethod?: string) => {
    if (paymentMethod?.toUpperCase() === 'SBER') {
      return sber
    } else if (paymentMethod?.toUpperCase() === 'TINKOFF') {
      return tin
    } else if (paymentMethod?.toUpperCase() === 'RAIFFEISEN') {
      return rfs
    } else if (paymentMethod?.toUpperCase() === 'MC/VISA UAH') {
      return visaMir
    } else if (paymentMethod?.toUpperCase() === 'ALFABANK') {
      return alfa
    } else {
      return paymentMethod?.toUpperCase()
    }
  }

  const requesiteTitle = (type: string | undefined) => {
    switch (type) {
      case 'bank_account':
        return 'Номер счета';
      case 'bank_card':
          return 'Номер карты';
      case 'crypto_wallet':
          return 'Номер кошелька';
      case 'phone_number':
          return 'Номер телефона';
      default:
        return 'Номер реквизита'
    }
  }

  return (
    <Container>
      <Header>
        <img src={shield} alt='shield' />
        <h3>Пополнение</h3>
        {!isRedirectUrlExist && <Timer>{timeLeft}</Timer>}
      </Header>
      <P>ID тикета: {ticketId}</P>
      <FieldGroup>
        {merchantName?.toLowerCase() === 'chcmanual' ? (
          <Field image={chc} />
        ) : merchantName?.toLowerCase() === 'vovan' ? (
          <Field image={vovan} />
        ) : (
          <Field text={merchantName?.toUpperCase()} />
        )}
        {!isRedirectUrlExist && <Field text={accountType} />}
        {!isRedirectUrlExist ? (
          <SmallFieldGroup>
            <Labeled>
              <span>Сумма перевода</span>
              <Field text={amount} copy currency={currency} />
            </Labeled>
            {paymentMethod && !isRedirectUrlExist && (
              <Labeled>
                <span>Тип реквизита</span>
                {images.includes(paymentMethod.toUpperCase()) ? (
                  <Field image={renderImage(paymentMethod)} />
                ) : (
                  <Field text={paymentMethod} />
                )}
              </Labeled>
            )}
          </SmallFieldGroup>
        ) : (
          <Labeled>
            <span>Сумма перевода</span>
            <Field text={amount} copy currency={currency} />
          </Labeled>
        )}
        {!isRedirectUrlExist && (
          <Labeled>
            <span>{requesiteTitle(requesiteType)}</span>
            <Field copy text={accountNumber} />
          </Labeled>
        )}
        {holderName && (
          <Labeled>
            <span>Держатель карты</span>
            <Field copy text={holderName} />
          </Labeled>
        )}
        {qrCodeData && (
          <>
            <p>Или отсканируйте QR-код</p>
            <QRCodeDiv>
              <QRCode value={qrCodeData} />
            </QRCodeDiv>
          </>
        )}
        <ButtonGroup>
          <Button variant='secondary' onClick={onCancel}>
            Отмена
          </Button>
          <Button onClick={onConfirm}>
            {isRedirectUrlExist ? 'Перейти к оплате' : 'Я оплатил'}
          </Button>
        </ButtonGroup>
      </FieldGroup>
    </Container>
  )
}

export default ActiveTransaction
