import { FC } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import TransactionPage from './pages/TransactionPage'
import AppLayout from './ui/AppLayout'

interface AppProps {}

const App: FC<AppProps> = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path='/:uuid' element={<TransactionPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
