import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: 100dvh;
  max-width: 100%;
  padding: 24px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
`

const Container = styled.div`
  width: 100%;
  /* height: 512px; */
  /* margin: 0 auto; */
  /* padding: 16px; */
  display: flex;
  align-items: center;
  justify-content: center;
`

const AppLayout = () => {
  return (
    <Wrapper>
      <Container>
        <Outlet />
      </Container>
    </Wrapper>
  )
}

export default AppLayout
