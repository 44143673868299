import isPropValid from '@emotion/is-prop-valid'
import ReactDOM from 'react-dom/client'
import { StyleSheetManager } from 'styled-components'
import App from './App'
import './styles/index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StyleSheetManager shouldForwardProp={(prop) => isPropValid(prop)}>
    <App />
  </StyleSheetManager>
)
