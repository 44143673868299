import { FC } from 'react'
import styled from 'styled-components'
import error from '../assets/images/error.svg'
import Button from '../ui/Button'

const Container = styled.div`
  height: auto;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  background-color: var(--color-gold-light);
  border-radius: var(--border-radius);
  padding: 16px;
`

const ErrorText = styled.p`
  color: var(--color-red);
  font-size: 20px;
  font-weight: 500;
`

const P = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 7px;
`

const MessageText = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 7px;
  color: var(--color-red);
`

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`

const StyledField = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 1rem;
  background-color: var(--color-bg);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-red);
  padding: 10px;
  outline: none;
  min-width: 280px;
`

interface CancellationProps {
  merchantName?: string
  merchantImage?: string
  currency?: string
  ticketId?: string
  paymentMethod?: string
  paymentMethodImage?: string
  amount?: number
  onConfirm: () => void
}

const Cancellation: FC<CancellationProps> = ({
  amount,
  merchantName,
  onConfirm,
  paymentMethod,
  ticketId,
  currency = '₽',
  merchantImage,
  paymentMethodImage,
}) => {
  return (
    <Container>
      <img src={error} alt='error' width={35} height={35} />
      <ErrorText>Ошибка</ErrorText>
      <P>ID тикета: {ticketId}</P>
      <FieldGroup>
        <StyledField>
          {merchantImage && <img src={merchantImage} alt='merchant' />}
          <span>{merchantName}</span>
          <span style={{ marginLeft: 'auto' }}>{currency + amount}</span>
        </StyledField>
        <StyledField>
          {paymentMethodImage && (
            <img src={paymentMethodImage} alt='payment method' />
          )}
          <span>{paymentMethod}</span>
        </StyledField>
      </FieldGroup>
      <MessageText>
        Не удалось обработать ваш платеж. Пожалуйста, попробуйте еще раз
      </MessageText>
      <Button onClick={onConfirm}>Ок</Button>
    </Container>
  )
}

export default Cancellation
