import { FC } from 'react'
import loader from '../assets/images/loader.svg'

interface SpinnerProps {
  waitingForRequisite?: boolean
}

const Spinner: FC<SpinnerProps> = ({ waitingForRequisite }) => {
  return (
    <div
      className='loader'
      style={{
        marginTop: 'calc(50dvh - 50px)',
      }}
    >
      <img src={loader} alt='loader' />
      {waitingForRequisite && <p>Ожидание реквизитов...</p>}
    </div>
  )
}

export default Spinner
