import styled, { css } from 'styled-components'

interface ButtonProps {
  variant?: 'primary' | 'secondary'
}

const variants = {
  primary: css`
    color: #ffffff;
    background-color: var(--color-red);
  `,
  secondary: css`
    color: var(--color-red);
    background: var(--color-bg);
    box-shadow: inset 0 0 0 2px var(--color-red);
    box-sizing: border-box;
  `,
}

const Button = styled.button<ButtonProps>`
  border: none;
  border-radius: var(--border-radius);
  padding: 8px 28px;
  max-width: fit-content;
  width: 100%;

  ${(props) => variants[props.variant!]}
`

Button.defaultProps = {
  variant: 'primary',
}

export default Button
