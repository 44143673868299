import React, { useEffect } from 'react'
import styled from 'styled-components'
import success from '../assets/images/success.svg'

const Container = styled.div`
  height: 150px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: calc(50dvh - 100px) auto;
  text-align: center;
  color: var(--color-green);
  font-size: 18px;
  background-color: var(--color-gray);
  border-radius: var(--border-radius);
  padding: 16px;
`

interface Props {
  onRedirect: () => void
}

const Success: React.FC<Props> = ({ onRedirect }) => {
  useEffect(() => {
    const timer = setTimeout(onRedirect, 5000)
    return () => clearTimeout(timer)
  }, [onRedirect])

  return (
    <Container>
      <img src={success} alt='success' width={40} height={40} />
      <p>Ваш платеж будет зачислен в течение нескольких минут</p>
    </Container>
  )
}

export default Success
